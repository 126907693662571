import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import vue3JsonExcel from "vue3-json-excel"
import VueApexCharts from "vue3-apexcharts";

let app = createApp(App)
app.use(Quasar, quasarUserOptions)
app.use(store)
app.use(router)
app.use(vue3JsonExcel)
app.use(VueApexCharts)
app.mount('#app')
