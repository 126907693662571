<template>
  <q-layout view="lHh Lpr lFf">
    <q-page-container class="page">
      <router-view></router-view>
    </q-page-container>
  </q-layout>
</template>

<style>
.page {
  height: 100vh;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #f48e24 !important;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #ededed;
  box-shadow: inset 0 0 6px #ededed;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #f48e24;
  border-radius: 10px;
  box-shadow: inset 0 0 6px #f48e24;
}
</style>
