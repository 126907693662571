<template>
  <div class="row flex justify-center items-center login">
    <div class="card-login">
      <header class="header flex row q-pt-lg">
        <div class="row col-12 justify-center">
          <img class="logo-size" src="../assets/image/logo_braavos.png" />
        </div>
        <div class="row col-12 justify-center q-pa-md">
          <label> Seja bem-vindo ao sistema <strong>Braavos</strong>. </label>
        </div>
      </header>

      <body>
        <q-form ref="form_movement" autofocus>
          <form class="form q-gutter-md q-pa-lg">
            <div class="column q-px-lg">
              <q-select :options="companies" :rules="[(val) => !!val || 'O campo é obrigatório.']" outlined
                v-model="domain" dense>
                <template v-slot:prepend>
                  <q-icon name="o_public" />
                </template>
              </q-select>
            </div>
            <div class="column q-px-lg">
              <q-input outlined v-model="username" :rules="[(val) => !!val || 'O campo é obrigatório.']" dense
                @keydown.enter="login">
                <template v-slot:prepend>
                  <q-icon name="o_person" />
                </template>
              </q-input>
            </div>
            <div class="column q-px-lg">
              <q-input type="password" outlined v-model="password" :rules="[(val) => !!val || 'O campo é obrigatório.']"
                dense @keydown.enter="login">
                <template v-slot:prepend>
                  <q-icon name="o_lock" />
                </template>
              </q-input>
            </div>
            <div class="column q-px-lg">
              <q-inner-loading :showing="visible" />
              <q-btn color="orange-8" @click="login">entrar</q-btn>
            </div>
          </form>
        </q-form>
      </body>
      <footer>
        <div class="flex justify-center">
          <p>
            <b>Copyright &copy; {{ year }} - WTF - Todos os direitos
              reservados.</b>
          </p>
        </div>
      </footer>
    </div>
  </div>
</template>

<script setup>
import { useQuasar } from "quasar";
import AuthService from "@/services/AuthService";
import { ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";

const $q = useQuasar();
const router = useRouter();
const year = new Date().getFullYear();
const visible = ref(false);
const _authService = new AuthService();

let form_movement = ref(null);
let companies = ref([]);
let domain = ref("");
let username = ref("");
let password = ref("");

async function _listCompanies() {
  await _authService.listCompanies().then((response) => {
    response.map((item) => {
      companies.value.push({
        label: item.nome,
        value: item.ad + "|" + item.ip,
      });
    });
  });
}

async function login() {
  visible.value = true;
  let auth = {
    login: username.value,
    senha: password.value,
    dominio: domain.value.value,
  };
  form_movement.value.validate().then(async (success) => {
    if (success) {
      await _authService.authenticate(auth).then((response) => {
        visible.value = false;
        if (response.access_token != null) {
          localStorage.setItem("access_token", response.access_token);
          localStorage.setItem("userData", JSON.stringify(response.user[0]));

          if (response.user[0].id_permissao == 2) {
            router.push({ name: "layout" });
          } else {
            router.push({ name: "select-modules" });
          }
        }
      }).catch((e) => {
        $q.notify({
          message: e.response.data.message,
          color: "red-9",
          position: "top",
        });
        visible.value = false;
      });
    } else {
      $q.notify({
        message: "Preencha todos os campos. !",
        color: "red-9",
        position: "top",
      });
      visible.value = false;
    }
  });
}

onMounted(() => {
  _listCompanies();
});
</script>
<style lang="scss" scoped>
.login {
  height: 100vh;
  background-image: url("@/assets/image/bg_login.png");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.card-login {
  box-shadow: 0px 2px 20px 5px #0000001f;
  background-color: #f6f6f6;

  @media screen and (max-width: 820px) {
    width: 80%;
    height: 90vh;
  }

  @media screen and (min-width: 821px) {
    width: 30%;
    height: 90vh;
  }
}

.card-login body {
  @media screen and (max-width: 820px) {
    min-height: 30vh;
  }

  @media screen and (min-width: 821px) {
    min-height: 40vh;
  }
}

.logo-size {
  @media screen and (max-width: 820px) {
    height: 12vh;
  }

  @media screen and (min-width: 821px) {
    height: 17vh;
  }
}
</style>
