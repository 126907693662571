import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import jwt_decode from "jwt-decode";

var token = localStorage.getItem('access_token')
const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/select-modules',
    name: 'select-modules',
    component: () => import('../pages/SelectModules.vue'),
    // beforeEnter: (to, from, next) => {
    //   if (token != null) {
    //     next()
    //   } else {
    //     next(from)
    //   }
    // }
  },
  {
    path: "/cover_sheet",
    name: "cover_sheet",
    component: () => import('../pages/CoverSheet.vue'),
    // beforeEnter: (to, from, next) => {
    //   if (token != null) {
    //     next()
    //   } else {
    //     next(from)
    //   }
    // }
  },
  {
    path: '/layout',
    name: 'layout',
    component: () => import('../views/Layout.vue'),
    redirect: { path: '/collaborator' },
    children: [
      {
        path: "/collaborator",
        name: "collaborator",
        component: () => import('../pages/Collaborator.vue'),
      },
      {
        path: "/movements_list",
        name: "movements_list",
        component: () => import('../pages/MovementsList.vue'),
      },
      {
        path: "/official_delegate",
        name: "official_delegate",
        component: () => import('../pages/OfficialDelegate.vue'),
      },
      {
        path: "/aprovers_delegate",
        name: "aprovers_delegate",
        component: () => import('../pages/ApproveDelegate.vue'),
      },
      {
        path: "/usage_policies",
        name: "usage_policies",
        component: () => import('../pages/UsagePolicies.vue'),
      }
    ],
    // beforeEnter: (to, from, next) => {
    //   if (token != null) {
    //     next()
    //   } else {
    //     next(from)
    //   }
    // }
  },
  {
    path: "/management",
    name: "management",
    component: () => import('../views/ManagementLayout.vue'),
    redirect: { path: '/overview' },
    children: [
      {
        path: "/overview",
        name: "overview",
        component: () => import('../pages/Management/Overview.vue'),
      },
      {
        path: "/solicitation",
        name: "solicitation",
        component: () => import('../pages/Management/Solicitation.vue'),
      },
      {
        path: "/report",
        name: "report",
        component: () => import('../pages/Management/Report.vue'),
      },
      {
        path: "/history",
        name: "history",
        component: () => import('../pages/Management/History.vue'),
      },
      {
        path: "/outstanding_payments",
        name: "outstanding_payments",
        component: () => import('../pages/Management/Payments.vue'),
      },
    ],
    // beforeEnter: (to, from, next) => {
    //   if (token != null) {
    //     next()
    //   } else {
    //     next(from)
    //   }
    // }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
