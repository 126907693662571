import Axios from 'axios'
import { ENV } from './Env'
const createAxios = Axios.create({
    baseURL: ENV.BASE_URL
})

createAxios.interceptors.request.use((config) => {
    const token = localStorage.getItem('access_token')

    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }

    return config
}, (err) => {
    return Promise.reject(err)
})

createAxios.interceptors.response.use((response) => {
    return response
}, (error) => {
    if (error.response.status === 401) {
        localStorage.clear()
        window.location = '/'
    }
    return Promise.reject(error)
})

export default createAxios;