import axios from '../axios'

export default class AuthService {
    async authenticate(dados) {
        const res = await axios.post("api/v1/auth/login", dados);
        return res.data;
    }

    async listCompanies(){

        const res = await axios.get('api/v1/auth/empresas');
        return res.data;

    }

    async logout(){

        const res = await axios.get('api/v1/movement/logout');
        return res.data;

    }
}