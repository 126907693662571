var ENV = {}
if (window.location.hostname === 'braavos.wtfapps.com.br') {
    ENV = {
        APP_NAME: 'Braavos',
        APP_ENV: 'production',
        BASE_URL: 'https://apibraavos.wtfapps.com.br/',       
    }
}

if (window.location.hostname === 'braavos.wtfapps.dev.br') {
    ENV = {
        APP_NAME: 'Braavos',
        APP_ENV: 'dev',
        BASE_URL: 'https://apibraavos.wtfapps.dev.br/',
    }
}


if (window.location.hostname === 'localhost') {
    ENV = {
        APP_NAME: 'Braavos',
        APP_ENV: 'local',
        // BASE_URL: 'https://apibraavos.wtfapps.com.br/',       
        BASE_URL: 'http://127.0.0.1:8000/',
    }
}

export { ENV }